import { mapActions, mapGetters, mapMutations } from 'vuex'
import validationMixin from '@/mixins/validation'
import { debounce } from 'lodash'
import moment from 'moment'

export default {
    name    : 'user-students-transactions',
    mixins  : [validationMixin],
    data () {
        return {
            valid                   : false,
            page                    : 1,
            //limit                   : 100,
            showRemoveDialog        : false,
            showReverseDialog       : false,
            deletedItem             : null,
            updateItemData          : null,
            //userTransactionsLength  : 0,
            payload                 : {
                transactionId              : ''
            },
            spinnerReverse          : []
        }
    },
    computed  : {
        ...mapGetters({
            items           : 'userTransactions/items',
            itemsLength     : 'userTransactions/itemsLength',
            
            isLoading       : 'userTransactions/isLoading',
            
            limit           : 'userTransactions/limit',
            skip            : 'userTransactions/skip'
        }),
        headers () {
            return [
                {
                    text    : 'ID',
                    value   : 'id'
                },
                {
                    text    : 'Created at',
                    value   : 'created_at'
                },
                {
                    text    : 'Status',
                    value   : 'status'
                },
                {
                    text    : 'Type',
                    value   : 'type'
                },
                {
                    text    : 'Amount',
                    value   : 'amount'
                },
                {
                    text    : 'Currency',
                    value   : 'currency'
                },
                {
                    text    : 'Commission',
                    value   : 'commission'
                },
                {
                    text    : 'Entity id',
                    value   : 'entity_id'
                },
                {
                    text    : 'Entity type',
                    value   : 'entity_type'
                },
                {
                    text    : 'Description',
                    value   : 'description'
                },
                {
                    text    : 'Actions',
                    value   : 'actions',
                    sortable: false
                }
            ]
        }
    },
    watch: {},
    created () {
        if (!this.items.length) {
            this.fetchItems({
                userId  : this.$route.params.id
            })
        }
        
        if (this.items) {
            if (this.skip !== 0) {
                this.page = Math.round(this.skip / this.limit) + 1
            }
        }
    },
    methods: {
        ...mapActions({
            fetchItems      : 'userTransactions/GET_USER_TRANSACTIONS',
            remove          : 'userTransactions/DELETE',
            reverse         : 'userTransactions/REVERSE'
        }),
        ...mapMutations({
            changeSkip      : 'userTransactions/CHANGE_SKIP'
        }),
        removeItem () {
            this.remove({
                userId  : this.$route.params.id,
                id      : this.deletedItem.id
            })
            .then(() => {
                this.showRemoveDialog = false;
                
                this.fetchItems({
                    userId  : this.$route.params.id
                })
                
                this.$toasted.success(this.$t('success_deleted'));
            })
        },
        reverseItem () {
            this.reverse({
                userId  : this.$route.params.id,
                id      : this.updateItemData.id
            })
            .then(() => {
                this.showReverseDialog = false;
                
                this.fetchItems({
                    userId  : this.$route.params.id
                })
                
                this.$toasted.success(this.$t('success_deleted'));
            })
        },
        changeDialogDelete (item = null) {
            this.showRemoveDialog   = !!item;
            this.deletedItem        = item;
        },
        changeDialogReverse (item = null, status = false) {
            if(item == null){
                this.showReverseDialog  = false;
                this.updateItemData     = null;
                return;
            }
            
            const index = this.spinnerReverse.indexOf(item.id);
            
            if(index != -1){
                return;
            }
            
            this.showReverseDialog  = status;
            this.updateItemData     = item;
        },
        changePagination (index) {
            this.changeSkip(this.limit * (index - 1));
            
            this.$vuetify.goTo(0);
            
            this.fetchItems({
                userId  : this.$route.params.id,
                limit   : 300
            });
        },
        formattedDate (date) {
            if (!date) return '';
            
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },
        createRequestPayload () {
            const formData = new FormData();
            
            //formData.append('available_to'  , this.formattedDate(this.availableTo));
            formData.append('userId'        , this.$route.params.id);
            
            if (this.editedItem) {
                formData.append('_method'       , 'patch');
                formData.append('id'            , this.editedItem.id);
            }else{
                //formData.append('tariff_id'       , this.payload.id);
            };
            
            return formData;
        }
    },
    destroyed () {
        //this.setUserTariffs([]);
        //this.setTariffs([]);
    }
}
